import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { firebaseConfig, siteKey } from "./environments/env";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import Vue from "vue";

export const firebase = initializeApp(firebaseConfig);

function getCookieConsentCookie() {
  return Vue.$cookies.get("cookie-consent");
}

export function checkConsentCookie() {
  const cookie = getCookieConsentCookie();
  if (cookie) {
    if (cookie.tracking) {
      console.log("tracking");
      const analytics = getAnalytics(firebase);
    }

    if (cookie.strictlyNecessary) {
      console.log("strictlyNecessary");
      const appCheck = initializeAppCheck(firebase, {
        provider: new ReCaptchaV3Provider(siteKey),
        isTokenAutoRefreshEnabled: true,
      });
    }
  }
}
export default firebase;
