// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: "AIzaSyA11GkUnaszAMf-T2qz6ofaVXeNim2gBbo",
  authDomain: "omegavr-prod.firebaseapp.com",
  projectId: "omegavr-prod",
  storageBucket: "omegavr-prod.appspot.com",
  messagingSenderId: "848943002064",
  appId: "1:848943002064:web:182769cce53300f5ed49d5",
  measurementId: "G-3NLL5DDC94",
};
export const siteKey = "6LdCbDUiAAAAAC9KE74Crg5YxXMmgVO36ozNAbwV";
