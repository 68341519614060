<template>
  <div>
    <v-btn
      color="primary"
      elevation="10"
      id="open_preferences_center"
      href="#"
      fab
      class="scroll-top-btn"
      @click="isOpenSettings = true"
    >
      <v-icon large>mdi-cookie</v-icon>
    </v-btn>
    <div class="cookie-consent" v-if="isCookieConsent">
      <v-container>
        <h3>Ihre Privatsphäre ist uns wichtig</h3>
        <p>
          Diese Website verwendet Cookies und Targeting Technologien, um Ihnen
          ein besseres Internet-Erlebnis zu ermöglichen und die Werbung, die Sie
          sehen, besser an Ihre Bedürfnisse anzupassen. Diese Technologien
          nutzen wir außerdem, um Ergebnisse zu messen, um zu verstehen, woher
          unsere Besucher kommen oder um unsere Website weiter zu entwickeln.
        </p>
        <div class="consent-btn-div">
          <v-btn @click="acceptAllCookies">Alle akzeptieren</v-btn>
          <v-btn @click="declineAllCookies">Ablehnen</v-btn>
          <v-btn @click="isOpenSettings = true" color="grey">
            Einstellungen
          </v-btn>
        </div>
      </v-container>
    </div>
    <div v-if="isOpenSettings" class="cookie-consent-settings-div">
      <v-overlay color="black" opacity="0.3" value="True" absolute>
        <v-card class="cookie-consent-settings" elevation="2">
          <div class="card-header">
            <v-card-title>Cookie Einstellungen</v-card-title>
            <v-card-subtitle>Omega Virtual Reality</v-card-subtitle>
          </div>
          <v-btn
            fab
            dark
            small
            elevation="0"
            class="close-icon"
            color="transparent"
            @click="isOpenSettings = false"
          >
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
          <v-tabs vertical>
            <v-tab class="consent-tab text-wrap"> Ihre Privatsphäre </v-tab>
            <v-tab class="consent-tab text-wrap">
              Technisch notwendige Cookies
            </v-tab>
            <v-tab class="consent-tab text-wrap"> Funktions Cookies </v-tab>
            <v-tab class="consent-tab text-wrap">
              Tracking und Performance Cookies
            </v-tab>
            <v-tab class="consent-tab text-wrap">
              Targeting und Werbung Cookies
            </v-tab>
            <v-tab class="consent-tab text-wrap"> Mehr Informationen </v-tab>

            <v-tab-item>
              <h4>Ihre Privatsphäre ist uns wichtig</h4>
              <p>
                Cookies sind sehr kleine Textdateien, die auf Ihrem Rechner
                gespeichert werden, wenn Sie eine Website besuchen. Wir
                verwenden Cookies für eine Reihe von Auswertungen, um damit
                Ihren Besuch auf unserer Website kontinuierlich verbessern zu
                können (z. B. damit Ihnen Ihre Login-Daten erhalten bleiben).
                <br />
                <br />
                Sie können Ihre Einstellungen ändern und verschiedenen Arten von
                Cookies erlauben, auf Ihrem Rechner gespeichert zu werden,
                während Sie unsere Webseite besuchen. Sie können auf Ihrem
                Rechner gespeicherte Cookies ebenso weitgehend wieder entfernen.
                Bitte bedenken Sie aber, dass dadurch Teile unserer Website
                möglicherweise nicht mehr in der gedachten Art und Weise nutzbar
                sind.
              </p>
            </v-tab-item>
            <v-tab-item>
              <h4>Technisch notwendige Cookies</h4>
              <p>
                Diese Cookies sind für die Bereitstellung von Diensten, die über
                unsere Website verfügbar sind, und für die Verwendung bestimmter
                Funktionen unserer Website von wesentlicher Bedeutung.
                <br />
                <br />
                Ohne diese Cookies können wir Ihnen bestimmte Dienste auf
                unserer Website nicht zur Verfügung stellen.
              </p>
              <v-switch
                disabled
                v-model="cookies.strictlyNecessary"
                label="Immer aktiv"
              ></v-switch>
            </v-tab-item>
            <v-tab-item>
              <h4>Funktions Cookies</h4>
              <p>
                Diese Cookies werden verwendet, um Ihnen ein persönlicheres
                Erlebnis auf unserer Website zu ermöglichen und um sich an Ihre
                Entscheidungen zu erinnern, die Sie bei der Nutzung unserer
                Website getroffen haben.
                <br />
                <br />
                Beispielsweise können wir Funktions-Cookies verwenden, um Ihre
                Spracheinstellungen oder Ihre Anmeldedaten zu speichern.
              </p>
              <v-switch
                v-model="cookies.functionality"
                :label="cookies.functionality ? 'aktiviert' : 'deaktiviert'"
              ></v-switch>
            </v-tab-item>
            <v-tab-item>
              <h4>Tracking und Performance Cookies</h4>
              <p>
                Diese Cookies werden zum Sammeln von Informationen verwendet, um
                den Verkehr auf unserer Website und die Nutzung unserer Website
                durch Besucher zu analysieren.
                <br />
                <br />
                Diese Cookies können beispielsweise nachverfolgen, wie lange Sie
                auf der Website verweilen oder welche Seiten Sie besuchen. So
                können wir verstehen, wie wir unsere Website für Sie verbessern
                können.
                <br />
                <br />
                Die durch diese Tracking- und Performance-Cookies gesammelten
                Informationen identifizieren keinen einzelnen Besucher.
              </p>
              <v-switch
                v-model="cookies.tracking"
                :label="cookies.tracking ? 'aktiviert' : 'deaktiviert'"
              ></v-switch>
            </v-tab-item>
            <v-tab-item>
              <h4>Targeting und Werbung Cookies</h4>
              <p>
                Diese Cookies werden genutzt, um Werbung anzuzeigen, die Sie
                aufgrund Ihrer Surfgewohnheiten wahrscheinlich interessieren
                wird.
                <br />
                <br />
                Diese Cookies, die von unseren Inhalten und / oder
                Werbeanbietern bereitgestellt werden, können Informationen, die
                sie von unserer Website gesammelt haben, mit anderen
                Informationen kombinieren, welche sie durch Aktivitäten Ihres
                Webbrowsers in Ihrem Netzwerk von Websites gesammelt haben.
                <br />
                <br />
                Wenn Sie diese Targeting- oder Werbe-Cookies entfernen oder
                deaktivieren, werden weiterhin Anzeigen angezeigt. Diese sind
                für Sie jedoch möglicherweise nicht relevant.
              </p>
              <v-switch
                v-model="cookies.targeting"
                :label="cookies.targeting ? 'aktiviert' : 'deaktiviert'"
              ></v-switch>
            </v-tab-item>
            <v-tab-item>
              <h4>Mehr Informationen</h4>
              <p>
                Bei Fragen in Bezug auf unseren Umgang mit Cookies und Ihrer
                Privatsphäre kontaktieren Sie uns bitte. <br />
                Details finden Sie in unserer
                <v-btn class="pa-0" link plain to="/privacy_policy">
                  Datenschutzbestimmung
                </v-btn>
                .
              </p>
            </v-tab-item>
          </v-tabs>
          <v-card-actions>
            <v-btn class="save-btn" color="primary" @click="onSaveSettings()">
              Einstellungen speichern
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-overlay>
    </div>
  </div>
</template>

<script>
import { checkConsentCookie } from "../../../firebase";
export default {
  name: "CookieConsentComponent",
  data: () => ({
    isCookieConsent: true,
    isOpenSettings: false,
    cookies: {
      strictlyNecessary: true,
      functionality: false,
      tracking: false,
      targeting: false,
    },
  }),
  mounted() {
    let isCookieSet = this.$cookies.isKey("cookie-consent");
    this.isCookieConsent = !isCookieSet;
  },
  methods: {
    acceptAllCookies() {
      this.createConsentCookie(true, true, true, true);
      this.isCookieConsent = false;
    },
    declineAllCookies() {
      this.createConsentCookie(true);
      this.isCookieConsent = false;
    },
    onSaveSettings() {
      let c = this.cookies;
      this.createConsentCookie(
        c.strictlyNecessary,
        c.functionality,
        c.tracking,
        c.targeting
      );
      this.isOpenSettings = false;
      this.isCookieConsent = false;
    },
    createConsentCookie(
      strictlyNecessary = false,
      functionality = false,
      tracking = false,
      targeting = false
    ) {
      this.$cookies.set("cookie-consent", {
        strictlyNecessary: strictlyNecessary,
        functionality: functionality,
        tracking: tracking,
        targeting: targeting,
      });
      checkConsentCookie();
    },
  },
};
</script>

<style scoped lang="scss">
.scroll-top-btn {
  position: fixed;
  left: 1%;
  bottom: 3%;
  z-index: 1;
}

.card-header {
  padding: 2%;
  background-color: #121212;
}

.cookie-consent-settings-div {
  position: fixed;
  display: flex;
  justify-items: center;
  align-items: center;
  z-index: 98;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}

.cookie-consent-settings {
  background-color: #1e1e1e;
  margin: auto;
  min-height: 70%;
  max-width: 800px;
  width: 100%;
}
.cookie-consent {
  position: fixed;
  z-index: 98;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 20vh;
  background-color: #121212;
  padding: 2em;
}
.consent-btn-div {
  display: flex;
  column-gap: 10px;
  row-gap: 10px;
  flex-wrap: wrap;
}

.close-icon {
  position: absolute;
  top: 5%;
  right: 5%;
}

.v-tab {
  display: inline-block;
  text-align: start;
  width: 225px;
  height: auto !important;
  font-size: 14px;
  padding-top: 1.2em;
  padding-bottom: 1.2em;
}

.v-window-item {
  margin: 1em 2em;
}

.v-window-item h4 {
  color: #1bbcda;
  font-size: 20px;
}

.v-window-item p {
  font-size: 15px;
}

.save-btn {
  right: 0;
  margin: 0.5em 0.5em 0.5em auto;
}

.v-card__actions {
  background-color: #121212 !important;
}

@media #{map-get($display-breakpoints, 'xs-only')} {
  .consent-btn-div .v-btn {
    width: 100%;
  }
  .v-tabs {
    display: block;
  }
  .consent-tab .text-wrap .v-tab {
    width: 100vw !important;
  }
  .v-tab {
    background-color: #191919;
    width: 100% !important;
    padding-top: 7px;
    padding-bottom: 7px;
  }
}
</style>
