import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import de from "vuetify/src/locale/de";
import en from "vuetify/src/locale/en";
import TikTokIcon from "@/components/icons/TikTokIcon.vue";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { de, en },
    current: "de",
  },
  icons: {
    iconfont: "mdi",
    values: {
      tiktok: {
        component: TikTokIcon,
      },
    },
  },
  theme: {
    dark: true,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: "#1BBCDA",
        secondary: "#B641FA",
        accent: "#F9B316",
        error: "#991111",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#b96901",
        secondary_background: "#1e1e1e",
      },
    },
  },
});
