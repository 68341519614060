<template>
  <header>
    <div class="header-headline">
      <h1 class="text-wrap mb-3" style="line-height: 0.95">Virtual Reality</h1>
      <h1 class="text-wrap mb-3" style="line-height: 0.95">
        <span>{{ currentEvent }}</span>
      </h1>
      <h3 class="my-2">Dein Event. Unser Erlebnis. Überall.</h3>
      <!--          <v-btn fab color="transparent">-->
      <!--            <v-icon>mdi-chevron-right</v-icon>-->
      <!--          </v-btn>-->
    </div>

    <v-img
      class="header-img"
      eager
      contain
      src="@/assets/webp/man_with_vr.webp"
      alt="man with vr headset"
    />
  </header>
</template>

<script>
export default {
  name: "HomeHeader",
  data() {
    return {
      eventTypes: [
        "Erlebnis für ihr Event",
        "Weihnachtsfeier",
        "Firmenevent",
        "Kindergeburtstag",
        "Teambuilding",
        "Geburtstagsparty",
      ],
      currentEvent: "",
      intervalId: null,
    };
  },
  mounted() {
    this.startEventRotation();
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    startEventRotation() {
      let index = 0;
      this.currentEvent = this.eventTypes[index];
      this.intervalId = setInterval(() => {
        index = (index + 1) % this.eventTypes.length;
        this.currentEvent = this.eventTypes[index];
      }, 3500); // changes every 3.5 sec
    },
  },
};
</script>

<style scoped>
header {
  position: relative;
  height: 50vh;
  background: rgb(182, 65, 250);
  background: linear-gradient(
    225deg,
    rgba(182, 65, 250, 0.25) 0%,
    rgba(27, 188, 218, 0.25) 100%
  );
}

header > * {
  position: absolute;
}

.header-img {
  width: 15em;
  max-width: 90%;
  bottom: 0;
  right: 5%;
  opacity: 0.8;
  z-index: 0;
}

.header-headline {
  left: 5%;
  top: 30%;
  width: 50em;
  max-width: 90%;
  max-height: 100%;
  z-index: 1;
}
</style>
