











































































































































































































import Vue from "vue";
import GalleryComponent from "@/components/home/GalleryComponent.vue";
import HomeHeader from "../components/home/HomeHeader.vue";

export default Vue.extend({
  name: "HomeView",

  components: {
    HomeHeader,
    GalleryComponent,
  },
  mounted() {
    setInterval(() => {
      if (this.advertisementParallax < 2) {
        this.advertisementParallax++;
      } else {
        this.advertisementParallax = 0;
      }
    }, 5000);
  },
  data: () => ({
    advertisementParallax: 0,
    timer: undefined,
    socialMediaIcons: [
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/omegavr.de/",
      },
      {
        icon: "$tiktok",
        link: "https://www.tiktok.com/@omegavr",
      },
      {
        icon: "mdi-facebook",
        link: "https://www.facebook.com/Omega-Virtual-Reality-103708545829435",
      },
    ],
    images: [
      require("@/assets/webp/rlFootageHumanNormalized.webp"),
      require("@/assets/webp/games/CyberShockB.webp"),
      require("@/assets/webp/games/DeadAheadA.webp"),
      require("@/assets/webp/games/DeadAheadD.webp"),
      require("@/assets/webp/games/DeadAheadC.webp"),
      require("@/assets/webp/games/ArrowSongD.webp"),
      require("@/assets/webp/games/QuantumArenaD.webp"),
      require("@/assets/webp/games/CopsvsRobbersA.webp"),
    ],
  }),
  computed: {
    getCarouselHeight() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "300px";
      } else {
        return "700px";
      }
    },
  },
});
