
















import Vue from "vue";
import NavbarComponent from "@/components/app/NavbarComponent.vue";
import FooterComponent from "@/components/app/FooterComponent.vue";
import ScrollToTopComponent from "@/components/app/ScrollToTopComponent.vue";
import CookieConsentComponent from "./components/app/CookieConsentComponent.vue";
import { checkConsentCookie } from "../firebase";

export default Vue.extend({
  name: "App",
  components: {
    CookieConsentComponent,
    ScrollToTopComponent,
    NavbarComponent,
    FooterComponent,
  },
  mounted() {
    checkConsentCookie();
  },
});
