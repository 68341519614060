<template>
  <v-footer class="text-center" dark padless>
    <v-card flat tile width="100%">
      <v-card-text style="background-color: #252525">
        <v-btn
          v-for="iconObj in socialMediaIcons"
          :key="iconObj.icon"
          :href="iconObj.link"
          class="mx-5"
          icon
        >
          <v-icon size="24px">
            {{ iconObj.icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="row mx-auto">
        <v-row class="w-100" justify="center">
          <v-col cols="12" lg="3" md="4">
            <div class="w-80 my-5 mx-auto">
              <h3 class="mb-5">Links</h3>
              <v-btn block link text to="/privacy_policy"> Datenschutz </v-btn>
              <v-btn block link text to="/imprint"> Impressum </v-btn>
            </div>
          </v-col>
          <v-col cols="12" lg="3" md="4">
            <div class="w-80 my-5 mx-auto">
              <h3 class="mb-5">Kontakt</h3>
              <v-btn class="ma-2 d-block" :href="'mailto:' + email" text small>
                <v-icon class="mr-3">mdi-email</v-icon>
                {{ email }}
              </v-btn>
              <v-btn class="ma-2" text small :href="'tel:' + whatsapp">
                <v-icon class="mr-3"> mdi-whatsapp </v-icon>
                {{ whatsapp }}
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" lg="3" md="4">
            <div class="w-80 my-5 mx-auto">
              <h3 class="mb-5">Standort</h3>
              <v-img
                alt="Standort"
                class="mx-auto"
                max-height="150"
                style="cursor: pointer"
                @click="overlay = true"
                contain
                src="@/assets/webp/bw.webp"
                width="250px"
              ></v-img>
              <v-overlay z-index="0" :value="overlay">
                <v-btn fab color="primary" @click="overlay = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-img
                  alt="Standort"
                  class="mx-auto"
                  @click="overlay = false"
                  width="50%"
                  contain
                  src="@/assets/webp/bw.webp"
                />
              </v-overlay>
            </div>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text style="background-color: #121212">
        <v-icon small style="top: -2px"> mdi-copyright </v-icon> Copyright
        {{ new Date().getFullYear() }} — <strong>Omega Virtual Reality</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    email: "service@omegavr.de",
    phone: "+49 1633781482",
    whatsapp: "+49 1633781482",
    socialMediaIcons: [
      {
        icon: "mdi-instagram",
        link: "https://www.instagram.com/omegavr.de/",
      },
      {
        icon: "$tiktok",
        link: "https://www.tiktok.com/@omegavr",
      },
      {
        icon: "mdi-email",
        link: "mailto:service@omegavr.de",
      },
      {
        icon: "mdi-facebook",
        link: "https://www.facebook.com/Omega-Virtual-Reality-103708545829435",
      },
    ],
    overlay: false,
  }),
};
</script>
<style scoped>
.w-80 {
  width: 80%;
}
</style>
