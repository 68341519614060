<template>
  <v-btn
    color="secondary"
    elevation="10"
    fab
    class="scroll-top-btn"
    @click="$vuetify.goTo(0)"
  >
    <v-icon large>mdi-menu-up</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: "ScrollToTopComponent",
};
</script>

<style scoped>
.scroll-top-btn {
  position: fixed;
  left: 50%;
  bottom: 3%;
  transform: translateX(-50%);
  z-index: 1;
  opacity: 0.7;
}
</style>
