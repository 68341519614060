import Vue from "vue";
import Vuex from "vuex";
import { writeFormToDb } from "../../firestore";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {
    sendBookingForm(context, payload) {
      context.commit("", payload);
    },
  },
  modules: {},
});
