<template>
  <div class="carousel-group">
    <v-carousel
      v-model="carousel_index"
      cycle
      show-arrows-on-hover
      continuous
      :height="height"
      hide-delimiters
      class="rounded"
    >
      <v-carousel-item
        v-for="(image, i) in images"
        :key="i"
        :src="image"
      ></v-carousel-item>

      <v-slide-group
        v-model="carousel_index"
        class="carousel-small-img"
        show-arrows
        center-active
      >
        <v-slide-item
          v-for="(image, i) in images"
          :key="i"
          v-slot="{ active, toggle }"
        >
          <v-card
            elevation="0"
            class="ma-1"
            :class="active ? 'colored-button' : 'small-pic-outline-transparent'"
            @click="toggle"
          >
            <v-img :src="image" class="ma-1 small-img" />
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-carousel>
  </div>
</template>

<script>
export default {
  props: ["images", "height"],
  data: () => ({
    carousel_index: 0,
  }),
};
</script>
<style scoped lang="scss">
.small-pic-outline-transparent {
  background-color: transparent;
}
.carousel-group {
  position: relative;
}
.carousel-small-img {
  position: absolute;
  bottom: 10px;
}
.small-img {
  height: 100px;
  width: 150px;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .small-img {
    height: 50px;
    width: 75px;
  }
}
</style>
